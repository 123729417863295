/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getOrder,
  getOrdersList
} from 'api';
import {
  orderSerialzier,
  ordersListSerializer
} from './serializers';

const ordersInitialState = {
  isLoading: false,
  isLoadingOrder:false,
  isResolved: false,
  fromDate: null,
  toDate: null,
  search: null,
  pageCount: 0,
  pages: {},
  currentPage: null,
  list: [],
  order: null
};

const orders = createSlice({
  name: 'orders',
  initialState: ordersInitialState,
  reducers: {
    fetchOrdersStart(state) {
      state.isLoading = true;
    },
    fetchOrderStart(state) {
      state.isLoadingOrder = true;
      state.order = null
    },
    fetchOrdersSuccess(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        ...payload,
        list: ordersListSerializer(data),
      };
    },
    fetchOrderSuccess(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        isLoadingOrder: false,
        isResolved: true,
        order: data,
      };
    },
    changeQueryFilter(state, { payload }){
      const { search, currentPage } = payload;
      return {
        ...state,
        search,
        currentPage,
      }
    },
    fetchOrdersFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    fetchOrderFail(state) {
      state.isLoadingOrder = false;
      state.isResolved = true;
    },
    changeDates(state, { payload }) {
      const { fromDate, toDate } = payload;
      return {
        ...state,
        fromDate,
        toDate,
      };
    },
    changePage(state, { payload }) {
      state.currentPage = payload;
    },
    reset() {
      return ordersInitialState;
    },
  },
});

export default orders.reducer;

export const {
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchOrdersFail,
  fetchOrderStart,
  fetchOrderSuccess,
  fetchOrderFail,
  changeQueryFilter,
  changeDates,
  changePage,
  reset,
} = orders.actions;

// { fromDate, toDate, search, limit, page, status }
export const fetchOrders = params => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchOrdersStart());
  try {
    const response = await getOrdersList(params);
    const { fromDate, toDate, search } = params;
    dispatch(
      fetchOrdersSuccess({
        fromDate,
        toDate,
        search,
        ...response,
      }),
    );
  } catch (e) {
    dispatch(fetchOrdersFail(e));
  }
};


export const fetchOrder = ({
                             orderId,
                             callback
                           }) => async (dispatch, state) => {
  if (state.isLoadingOrder) {
    return;
  }
  dispatch(fetchOrderStart());
  try {
    const response = await getOrder(orderId);
    const serializedOrder = orderSerialzier(response);
    dispatch(
      fetchOrderSuccess({
        data: serializedOrder,
      }),
    );
    callback(serializedOrder)
  } catch (e) {
    dispatch(fetchOrderFail(e));
  }
};