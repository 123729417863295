import React, { useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dropdown from 'spotii-ui/Dropdown';
import Button from 'spotii-ui/Button';
import message from 'spotii-ui/Message';
import OrderRefund from 'components/OrderRefund';
import { orderCapture, orderVoid, downloadInvoice, downloadRefundInvoice } from 'api';
import { ORDER_STATUS_OPENED, ORDER_STATUS_REFUNDED, ORDER_STATUS_VOIDED } from 'utils/constants';
import OrderCapture from 'components/OrderCapture';
import {
  fetchOrder
} from "../../reducers/orders";
import {
  printReceipt
} from "../../utils/printReceipt";

const OrderActions = ({
  order,
  onActionSuccess,
  userCanRefund,
  ordersType,
  isUserFeeForMerchant,
}) => {
  const { canDownloadInvoice, allowPartialCapture } = useSelector(state => state.application);

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCaptureModal, setShowCaptureModal] = useState(false);
  const { status, id, totalRefund, displayReference, reference } = order;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const noRefundOption = [ORDER_STATUS_OPENED, ORDER_STATUS_REFUNDED, ORDER_STATUS_VOIDED];
  const handleDownloadInvoice = async () => {
    setIsLoading(true);
    try {
      await downloadInvoice(id, `Tax Invoice ${displayReference || reference}`);
      setIsLoading(false);
    } catch (e) {
      console.warn(e);
      message.error('Oops! Something went wrong.');
      setIsLoading(false);
    }
  };

  const handleDownloadRefundInvoice = async () => {
    setIsLoading(true);
    try {
      await downloadRefundInvoice(id, `Tax Credit Note ${displayReference || reference}`);
      setIsLoading(false);
    } catch (e) {
      console.warn(e);
      message.error('Oops! Something went wrong.');
      setIsLoading(false);
    }
  };

  const handleCapture = async () => {
    if (allowPartialCapture) {
      setShowCaptureModal(true);
    } else {
      setIsLoading(true);
      try {
        await orderCapture(id);
        setIsLoading(false);
        message.success(t('orderCaptureSuccess'));
        if (onActionSuccess) {
          onActionSuccess();
        }
      } catch (e) {
        console.warn(e);
        message.error(t('somethingWentWrong'));
        setIsLoading(false);
      }
    }
  };

  const handleRefund = async () => {
    setShow(true);
  };

  const handleVoid = async () => {
    setIsLoading(true);
    try {
      await orderVoid(id);
      setIsLoading(false);
      message.success(t('orderVoidSuccess'));
      if (onActionSuccess) {
        onActionSuccess();
      }
    } catch (e) {
      console.warn(e);
      message.error(t('somethingWentWrong'));
      setIsLoading(false);
    }
  };

  const handleReceiptPrinting = (order) => () => {
    dispatch(fetchOrder({
        orderId: order.orderId ,
        callback: printReceipt
      })
    );
  }

  return !canDownloadInvoice &&
    (ordersType === ORDER_STATUS_REFUNDED.toLowerCase() ||
      ordersType === ORDER_STATUS_VOIDED.toLowerCase()) ? (
    <></>
  ) : (
    <>
      <Dropdown alignRight>
        <Dropdown.Toggle as={Dropdown.CustomToggle}>
          <Button
            type="transparent"
            shape="square"
            size="small"
            icon={isLoading ? 'loading' : 'ellipsis'}
            disabled={isLoading}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {canDownloadInvoice && (
            <>
              <Dropdown.Item
                style={{ display: 'flex' }}
                eventKey="1"
                as="button"
                onClick={handleDownloadInvoice}
              >
                {t('downloadTaxInvoice')}
              </Dropdown.Item>
              {totalRefund > 0 ? (
                <Dropdown.Item
                  style={{ display: 'flex' }}
                  eventKey="2"
                  as="button"
                  onClick={handleDownloadRefundInvoice}
                >
                  {t('downloadTaxCreditNote')}
                </Dropdown.Item>
              ) : null}
            </>
          )}
          {status === ORDER_STATUS_OPENED ? (
            <Dropdown.Item
              style={{ display: 'flex' }}
              eventKey="2"
              as="button"
              onClick={handleCapture}
            >
              {t('capture')}
            </Dropdown.Item>
          ) : null}
          {noRefundOption.indexOf(status) === -1 && userCanRefund ? (
            <Dropdown.Item
              style={{ display: 'flex' }}
              eventKey="3"
              as="button"
              onClick={handleRefund}
            >
              {t('refund')}
            </Dropdown.Item>
          ) : null}
          {status === ORDER_STATUS_OPENED ? (
            <Dropdown.Item
              style={{ display: 'flex' }}
              eventKey="4"
              as="button"
              onClick={handleVoid}
            >
              {t('void')}
            </Dropdown.Item>
          ) : null}

          <Dropdown.Item
            style={{ display: 'flex' }}
            eventKey="2"
            as="button"
            onClick={handleReceiptPrinting(order)}
          >
            {t('printReceipt')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <OrderRefund
        order={order}
        userFee={isUserFeeForMerchant ? parseFloat(order.userFee) : 0}
        show={show}
        onClose={() => setShow(false)}
        onSuccess={() => {
          setShow(false);
          if (onActionSuccess) {
            onActionSuccess();
          }
        }}
      />
      <OrderCapture
        order={order}
        show={showCaptureModal}
        onClose={() => setShowCaptureModal(false)}
        onSuccess={() => {
          setShowCaptureModal(false);
          if (onActionSuccess) {
            onActionSuccess();
          }
        }}
      />
    </>
  );
};

OrderActions.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    userFee: PropTypes.number,
    totalRefund: PropTypes.number,
    displayReference: PropTypes.string,
    reference: PropTypes.string,
  }).isRequired,
  onActionSuccess: PropTypes.func,
  userCanRefund: PropTypes.bool.isRequired,
  ordersType: PropTypes.string,
  isUserFeeForMerchant: PropTypes.bool,
};

OrderActions.defaultProps = {
  onActionSuccess: null,
  ordersType: '',
  isUserFeeForMerchant: false,
};

export default OrderActions;