import {
  formatDate,
  formatDateTime
} from "./formatDate";

export const printReceipt = (order) => {
  let templateData= {
    merchantName: order.merchantName,
    createdAt: formatDateTime(order.createdAt),
    cashierId: order.employee.employeeId,
    description:`Order # ${order.reference}`,
    customerName: (order.customer.firstName||'') + ' ' + (order.customer.lastName||''),
    totalOrderAmount: order.total + ' ' + order.currency,
    orderId: order.reference,
    paymentDetails: '',
    branchAddress: order.employee.address || ''
  }
  fetch('/assets/template/order_receipt.html')
    .then((response) => response.text())
    .then((htmlContent) => {
      console.log(htmlContent)
      for (let installment of order.installments) {
        templateData.paymentDetails += `
        <tr>
          <td>${formatDate(installment.effectiveAt)}</td>
          <td>${installment.total} ${installment.currency}</td>
          <td>${installment.status}</td>
        </tr>
        `
      }

      for (var key in templateData) {
        htmlContent = htmlContent.replaceAll(`{{${key}}}`,templateData[key])
      }
      const printWindow = window.open('', '_blank');
        printWindow.document.write(htmlContent);
        // printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        // printWindow.close();
    })
    .catch((error) => {
      console.error('Error loading HTML file:', error);
    });
}
